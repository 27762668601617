import { Link, graphql } from 'gatsby';
import React, { useState, useEffect } from "react";
import "../styles/categories.scss"
import SecondaryLayout from "../layouts/secondary-layout";
import List from '../components/list/list';

export const query = graphql`
  query MenuTypes ($id: Int) {
    strapiMenuTypes(strapiId: { eq: $id }) {
        Title
        Slug
        submenu_types {
            created_at
            published_at
            Title
            Slug
          }
    }
    allStrapiSubmenuTypes {
        edges {
          node {
            Slug
            ProductList {
                GroupTitle
                product {
                  Title
                  Price
                  Description
                  Vegan
                  Vegetarian
                  Nuts
                  Spicy
                }
            }
          }
        }
    }
    strapiSiteSettings {
        DisclaimerAllergies
        FoodURL
        DrinksURL
    }
  }
`;

const Categories = ({ data }) => {

    const { Title, Slug, submenu_types } = data.strapiMenuTypes

    submenu_types.sort(function (firstType, secondType) {

        let secondDate = secondType.created_at;
        let firstDate = firstType.created_at;

        return new Date(firstDate) - new Date(secondDate);
    });

    const contentsList = [];

    let categoryLocation = ""
    if (typeof window !== 'undefined' && window.location.search.split('=').length > 1) {
        categoryLocation = window.location.search.split('=')[1];
    }

    // const font = require("../assets/main-font.ttf");
    // console.log(font, "test")

    return (

        <SecondaryLayout SEOTitle={""} SEODescription={""}>
            <div className="categories-font">
                <div className="page-content">
                    <div>
                        <h1 className="page-title">{Title}</h1>
                        <p className="complementary-subtitle">
                            ..or see our<a href={Title == "Drinks" ? data.strapiSiteSettings.FoodURL : data.strapiSiteSettings.DrinksURL}>{Title == "Drinks" ? " ‎ food ‎ " : " ‎ drinks ‎ "}</a>menu &#128522;</p>
                    </div>

                    <div className="menu-list">
                        <ul className="categories-list">

                            {
                                submenu_types ?
                                    submenu_types.map((item, index) => {
                                        return (
                                            <li key={index} className="category">
                                                <Link to={`/menu/${Slug}/?type=` + item.Slug} className={`${item.Slug == categoryLocation ? "category-link-selected" : "category-link"}`}>
                                                    {item.Title}
                                                </Link>
                                            </li>
                                        )
                                    })
                                    : ""
                            }

                        </ul>
                        <ul className="categories-list"> 
                        <li className="category"> <a href={'/assets/classic-menu.pdf?t='+new Date().getTime()} target="_blank" rel="noopener noreferrer" className="category-link">Classic set menu</a></li>
                            <li className="category"> <a href={'/assets/gourmet-menu.pdf?t='+new Date().getTime()} target="_blank" rel="noopener noreferrer" className="category-link">Gourmet set menu</a></li>
                            <li className="category"> <a href={'/assets/indulgence-menu.pdf?t='+new Date().getTime()} target="_blank" rel="noopener noreferrer" className="category-link">Indulgence set menu</a></li>

                        </ul>
                    </div>

                    {/* <p className="height"></p> */}

                    <div>
                        {
                            submenu_types?.map((itemSubmenu, index) => {
                                return (
                                    data.allStrapiSubmenuTypes.edges?.map((itemType, index) => {
                                        if (itemType.node.Slug == itemSubmenu.Slug && itemType.node.Slug == categoryLocation) {

                                            return (
                                                <>
                                                    {
                                                        itemType.node.ProductList?.map((itemProducts, index) => {

                                                            contentsList.push({
                                                                GroupTitle: itemProducts.GroupTitle ? itemProducts.GroupTitle : null,
                                                                Products: itemProducts.product ? itemProducts.product : null,

                                                            })
                                                        })

                                                    }
                                                </>
                                            )
                                        }
                                    })
                                )
                            })
                        }
                    </div>

                    {
                        contentsList ?
                            <div className="products-listing">
                                <List
                                    contents={contentsList}
                                ></List>

                                {
                                    data.strapiSiteSettings.DisclaimerAllergies ?
                                        (Title == "Food" || Title == "Dessert") ?
                                            <div className="disclaimer">
                                                <p>{data.strapiSiteSettings.DisclaimerAllergies}</p>
                                            </div>
                                            : ""
                                        : ""
                                }

                            </div>
                            : ""
                    }


                </div>
            </div>
        </SecondaryLayout>

    )
}

export default Categories;